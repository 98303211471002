import * as React from "react"
import { useEffect, useState } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import SingleSession from "../templates/singlesession"

const Session = (props) => { 
    const [idSession, setIdSession] = useState();    
    let id;
    if(props.location?.state) {
      id = props.location?.state?.id;
    } else {
      if(props.location?.search) {
        id = props.location.search.replace('?id=', '');
      }
    }
    useEffect(() => {
      setIdSession(id);
    }, [id]);

    return (
  <Layout>
    <Seo title="Session" />    
      {
      (idSession) ?
      <SingleSession id={idSession} browsemode={false}></SingleSession>  
      :
      <React.Fragment></React.Fragment>
      }      
  </Layout>
)}

export default Session
import * as React from "react"
import { useEffect, useState } from 'react';
import sessions from "../../data/sessions.json"
import * as helper from './../helper/utils.js';
import * as statusHelper from './../helper/status.js';
import ICalendarLink from "react-icalendar-link";
import { Badge, Button } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar'

const SingleSession = (props) => {    
  const [session, setSession] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState(props.id);
  const [calEntry, setCalEntry] = useState();
  const [progress, setProgress] = useState();

  let selectedSessions;
  if (typeof window !== `undefined`) {
    selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));
  }
    
  async function wrapStatusSave(newStatus) {        
    await statusHelper.save(id, newStatus);
    setStatus(newStatus);
    if (typeof window !== `undefined`) {
      selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));
    }
    if(props.browsemode) {
      let pending  = sessions.data.filter(o=> !selectedSessions.some(i=> i.id === o.id));
      setId(pending[0].id);
    }
  }

  useEffect(() => {
    if(sessions.data) {
      
      setProgress((selectedSessions?.length / sessions.data.length) * 100)
      let pos = sessions.data.findIndex(x => x.id === id.toString());
      console.log('sessions.data[pos]', sessions.data[pos]);

      setSession(sessions.data[pos]);
      if(sessions.data[pos]) {
        const newEvent = {
          title: sessions.data[pos].title,
          startTime: sessions.data[pos].begin,
          endTime: sessions.data[pos].end,
          location: sessions.data[pos].location?.label_en      
        }
        setCalEntry(newEvent);
      }      
      if(selectedSessions) {
        let currentStatus = selectedSessions.find(x => x.id === id)
        if(currentStatus) { 
          setStatus(currentStatus.status);
        } else {
          setStatus();
        }
      }            
    }
  }, [id, props.id]);

  return(
  
    <React.Fragment>    
    { !session ?    
      <React.Fragment></React.Fragment>
    :
    <React.Fragment>
    { (props.browsemode && progress)
    ?
    <React.Fragment>
      <ProgressBar now={progress} label={`Your progress: ${Math.round(progress)}%`} />
      <p></p>
    </React.Fragment>
    :
    <React.Fragment></React.Fragment>
    }    
    <Button variant="light" size="sm" onClick={() => wrapStatusSave(1)} style={{ backgroundColor: statusHelper.checkSelected(status, 1) }}>{helper.nameStatus(1)}</Button>&nbsp;
    <Button variant="light" size="sm" onClick={() => wrapStatusSave(2)} style={{ backgroundColor: statusHelper.checkSelected(status, 2) }}>{helper.nameStatus(2)}</Button>&nbsp;
    <Button variant="light" size="sm" onClick={() => wrapStatusSave(3)} style={{ backgroundColor: statusHelper.checkSelected(status, 3) }}>{helper.nameStatus(3)}</Button>&nbsp;
    <Button variant="light" size="sm" onClick={() => wrapStatusSave(4)} style={{ backgroundColor: statusHelper.checkSelected(status, 4) }}>{helper.nameStatus(4)}</Button>&nbsp;
    <p></p>
    <Badge bg={session.track?.id}>{helper.trackName(session.track.id)} </Badge> 
    {(session.subconference?.id === "-txt-long-live-the-word-") ? <React.Fragment>&nbsp;<Badge bg='txt'>.txt</Badge></React.Fragment> : ''}
    {(session.subconference?.id === "tincon-x-re-publica-22") ? <React.Fragment>&nbsp;<Badge bg='TINCON'>TINCON</Badge></React.Fragment> : ''}
    <p></p>
    <h2>{session.title}</h2>
    <p><b>{session?.day?.label_en}</b> {helper.niceDate(session?.begin, true, true, true)} - {helper.niceDate(session?.end, true, true, true)} ({session?.duration} min)</p>

    {session.speakers.map((data, key) => {
        return (
          <div key={key}>{data.name}</div>
        );
      })}    
    <p></p>
    <p>{session?.location?.label_en}, {session?.type}, {session?.lang?.label_en}</p>    
    <p>{session?.abstract}</p>
    <p>{session?.description}</p>
    {(session?.cancelled) ? <p><Badge bg="danger">CANCELED</Badge></p> : <React.Fragment></React.Fragment>}
    <p>
      <ICalendarLink event={calEntry}>Download iCal file</ICalendarLink>
    </p>

    </React.Fragment>
    }
    
    </React.Fragment>  
  )
}

export default SingleSession
